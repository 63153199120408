import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './mobile-banner.css'

const MobileBanner = () => {
  const [windowState, setWindowState] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const owl = require("react-owl-carousel");
      if (owl) {
        setWindowState(true);
      }
    }
  }, []);
  return (
    <OwlCarousel
      dots={false}
      loop
      autoplaySpeed={3500}
      autoplayTimeout={5000}
      autoplay={true}
      autoplayHoverPause={false}
      className="owl-carousel owl-theme showcase-slider"
      items={1}
      margin={0}
      nav={true}
      navText={["<div class='prev'>&lt;</div>", "<div class='next'>&gt;</div>"]}
    >
      <div className="item">
        <img src="https://emobi.in/sliders/slider-1.png" />
      </div>
      <div className="item">
        <img src="https://emobi.in/sliders/slider-2.png" />
      </div>
      <div className="item">
        <img src="https://emobi.in/sliders/slider-3.png" />
      </div>
      <div className="item">
        <img src="https://emobi.in/sliders/slider-4.png" />
      </div>
      <div className="item">
        <img src="https://emobi.in/sliders/slider-5.png" />
      </div>
    </OwlCarousel>
  );
};

export default MobileBanner;
