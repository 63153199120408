import React from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './banner.css';
import { Link } from 'react-scroll';
import LaunchVideo from '../LaunchVideo';
import MobileBanner from '../MobileBanner';

const LaunchBanner = () => {

  return (
    <div className='banner-launch'>
      <div className='d-none d-sm-block'>
        <LaunchVideo />
        <div className='launch-banner-caption'>
          <h1>
            🎉 Unlocked <span>New Horizons 🎉</span>
          </h1>
          <Link
            to="contactPage"
            spy
            offset={-120}
            smooth
            duration={1000}
            containerId="scrollElement"
            href="#"
            className='btn btn-primary'
          >
            Know more
          </Link>

        </div>
      </div>
      <div className='d-block d-sm-none mob-banner'>
        <MobileBanner />
        <div className='launch-banner-caption launch-banner-caption-mobile'>
          <h1>
            🎉 Unlocked <span>New Horizons 🎉</span>
          </h1>
          <Link
            to="contactPage"
            spy
            offset={-120}
            smooth
            duration={1000}
            containerId="scrollElement"
            href="#"
            className='btn btn-primary'
          >
            Know more
          </Link>

        </div>
      </div>
    </div>
  )
}

export default LaunchBanner
