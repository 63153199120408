import React from 'react';
import { Col } from 'react-bootstrap';
import './video.css';

const LaunchVideo = () => {
  return (
    <div className="launch-video-vertical-space bg-video">
      <div className='overlay-video'></div>
      <Col>
        <div className="launch-video-wrapper-og">
          <video loop muted autoPlay>
            <source src="https://emobi.in/video.mp4" type="video/mp4" />
            <track src="https://emobi.in/video.mp4" kind="subtitles" srclang="en" label="English" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Col>
    </div>
  )
}

export default LaunchVideo;
