import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../commons.css";
import WhoWeAre from "../components/WhoWeAre";
import Contact from "../components/Contact";
import Footer from "../shared/Footer";
import Layout from "./Layout";
import { Element } from "react-scroll";
import PressRelease from "../components/PressRelease";
import ApplicationHelmet from "../components/Helmet";
import LaunchBanner from "../components/LaunchBanner";
import Podcast from "../components/Podcast";
 
import About from '../components/About';
import Product from '../components/Product';
import Video from "../components/Video";
import TwoBikes from "../components/TwoBikes";
import Slider from "../components/Slider";
import AboutKyaari from "../components/AboutKyaari";
import KyaariProductSpec from "../components/KyaariProductSpec";
 
const App = () => {
  return (
    <ApplicationHelmet>
      <Layout isNotHomePage={false}>
        <Element name="homePage">
          <LaunchBanner isNotHomePage={false} />
        </Element>
        <AboutKyaari />
        <KyaariProductSpec />
        <About />
        <Product />
        <Video />
        <Slider />
        <TwoBikes />
        <div id="aboutPage">
          <WhoWeAre />
        </div>
        <div id="pressRelease">
          <PressRelease />
        </div>
        <Podcast />
        <div id="contactPage">
          <Contact />
        </div>
        <Footer />
      </Layout>
    </ApplicationHelmet>
  );
};
 
export default App;