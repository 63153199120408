import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./pod.css";

const Podcast = () => {
  return (
    <div className="bg-pod">
      <Container>
        <h3>Shape of Work podcast's interview is <br /> now live on Spotify and Apple Podcasts!</h3>
        <Row>
          <Col xs={12} md={6}>
            <iframe
              src="https://open.spotify.com/embed/episode/5dqnZ0ertN4TrfIyJTYF3p?utm_source=generator"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              key={"spotify"}
              className="iframe-wrapper"
            />
          </Col>
          <Col xs={12} md={6}>
            <iframe
              key={"apple"}
              allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
              frameborder="0"
              className="iframe-wrapper"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              src="https://embed.podcasts.apple.com/us/podcast/510-bharath-rao-on-exploring-the-future-of/id1572231455?i=1000638325617"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Podcast;
