import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../About/about.css";

const AboutKyaari = () => {
  return (
    <div
      className="abt-vertical-space bg-short-about"
      style={{
        backgroundImage: "url('pattern-bg.jpg')",
      }}
    >
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={6} md={12} lg={6}>
            <h1 className="about-title">
              <span className="kyari-text" data-text="KYARI">
                KYARI
              </span>
              <br />
            </h1>
          </Col>
          <Col xs={12} sm={6} md={12} lg={6} className="b-left">
            <p>
              Introducing Kyari, <strong>EMOBI's</strong> groundbreaking electric two-wheeler
              designed for B2B success, blending affordability with innovation.
              Inspired by <strong>Japanese culture</strong> and crafted in collaboration with
              <strong> Honda and Musashi</strong>, Kyari features the most advanced powertrain,
              representing a new era of accessible automotive excellence.
              Welcome to an era of budget-friendly driving innovation with Kyari.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutKyaari;
