import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./product.css";
import { MdOpenInNew } from "react-icons/md";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const KyaariProductSpec = () => {
  const containerRef = useRef(null);

  return (
    <div
      id="abc"
      ref={containerRef}
      className="bg-product bg-product-specs"
      style={{
        backgroundImage: "url('https://emobi.in/kyari-specs-banner.png')",
      }}
    >
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <ul className="key-points">
              <AnimationOnScroll
                animateOnce
                offset={80}
                scrollableParentSelector="#scrollElement"
                animateIn="animate__fadeInLeftBig"
                delay={50}
              >
                <li>
                  <h3>
                    <span>4.2</span> <small>Kw</small> <p>Peak power</p>
                  </h3>
                </li>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateOnce
                scrollableParentSelector="#scrollElement"
                animateIn="animate__fadeInLeftBig"
                delay={100}
              >
                <li>
                  <h3>
                    <span>75</span> <small>Kmph</small> <p>Top speed</p>
                  </h3>
                </li>
              </AnimationOnScroll>
              <AnimationOnScroll
                offset={150}
                animateOnce
                scrollableParentSelector="#scrollElement"
                animateIn="animate__fadeInLeftBig"
                delay={150}
              >
                <li>
                  <h3>
                    <span>0</span> <small>Mins</small> <p>Swappable Battery</p>
                  </h3>
                </li>
              </AnimationOnScroll>
            </ul>
          </Col>
          <a
            className="see-all-spec"
            role="button"
            target="_blank"
            href="https://emobi.in/kyari.pdf"
          >
            See all specifications <MdOpenInNew />
          </a>
        </Row>
      </Container>
    </div>
  );
};

export default KyaariProductSpec;
